import React, { ReactElement } from "react";
import clsx from "clsx";
import FirestoreImage from "src/components/base/Images/FirestoreImage";

export default function Cover({ className, category, src, alt, background, border, width, height }: CoverProps): ReactElement {
  const realisationClass = clsx(
    className,
    [
      'relative',
      'grow',
    ],
  );
  const legendClass = clsx(
    [
      '-translate-x-1',
      'rotate-180',
      'pr-2',
      'h-6',
      'font-light',
      'text-sm',
    ],
    {
      'text-secondary': background === 'primary',
      'bg-primary': background === 'primary',
      'text-primary': background === 'light',
      'bg-light': background === 'light',
      'bg-secondary': background === 'secondary',
      'pl-32': !category,
      'pl-16': category,
    }
  );
  const borderClass = clsx(
    [
      'absolute',
      'top-2',
      'right-2',
      'border',
      'h-full',
      'w-full',
      'rotate-180',
    ],
    {
      'border-primary': border === 'primary',
      'border-secondary': border === 'secondary',
      'border-light': border === 'light',
    }
  );

  return (
    <div className={realisationClass}>
      <FirestoreImage file={src} alt={alt} className="h-full object-cover pb-4" width={width} height={height} />
      <fieldset className={borderClass}>
        <legend className={legendClass}>
          {category}
        </legend>
      </fieldset>
    </div>
  );
}
