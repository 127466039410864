import React, { ReactElement } from 'react';
import clsx from 'clsx';
import Icon from '../Icons/Icon';
import P from '../Texts/P';

export default function Error({ message, id, className, type, onClose }: ErrorProps): ReactElement {
  const popupClass = clsx(
    className,
    [
      "py-4 px-8",
      "text-white",
      "relative"
    ],
    {
      "bg-primary": type === "success",
      "bg-red": type === "error",
    }
  )

  return (
    <div className={popupClass} id={id}>
      <Icon name="close" className="absolute top-4 right-4 cursor-pointer" onClick={onClose} />
      <P>
        {message}
      </P>
    </div>
  );
}
