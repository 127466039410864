import { collection } from "firebase/firestore";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Body from "src/components/layout/Body";
import { db, storage } from "src/firebase";
import { ref as storageRef } from "firebase/storage";
import Cover from "src/components/elements/Realisation/Cover";
import Link from "src/components/base/Links/Link";

export default function RealisationView() {
  const currentCollection = collection(db, "realisations");
  const [realisations] = useCollection(currentCollection);

  return (
    <Body color="primary">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
        {
          realisations && realisations.docs.map((realisation) => (
            <div className="relative transition hover:opacity-75" key={realisation.id}>
              <Link to={`/realisation/${realisation.id}`}>
                <Cover
                  className="h-80 sm:h-128"
                  src={storageRef(storage, realisation.data().cover)}
                  category="Réalisation"
                  alt={
                    realisation.data().images.find(
                      (a: FirestoreImage) =>
                        a.fullPath === realisation.data().cover
                    )?.alt
                  }
                  background="primary"
                  border="secondary"
                  height={512}
                  width={512}
                />
                <div className="absolute w-full bottom-0 flex justify-center">
                  <div className="bg-secondary p-4 text-dark text-xl m-16 break-normal w-2/3 text-center font-cherolina">
                    {realisation.data().title}
                  </div>
                </div>
              </Link>
            </div>
          ))
        }
      </div>
    </Body>
  )
}
