import React, { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import logo from 'src/assets/img/logo/full_secondary.svg';
import Image from 'src/components/base/Images/Image';
import Icon from 'src/components/base/Icons/Icon';
import { ReactComponent as Instagram } from 'src/assets/icons/instagram.svg';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/firebase';

export default function Header({ className }: BasicProps): ReactElement {
  const menuRef = React.useRef<HTMLUListElement>(null);
  const closeBtnRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const headerClass = clsx(
    className,
    [
      'flex',
      'flex-col',
      'md:space-y-16',
      'w-full',
      'items-center',
      'p-8 sm:p-16 md:px-32'
    ],
  );
  const menuClass = clsx(
    [
      'flex',
      'flex-col',
      'md:flex-row',
      'justify-between',
      'w-full',
      'md:flex',
      'space-y-2 md:space-y-0',
      'overflow-hidden',
    ],
    {
      'h-0 md:h-fit': !isOpen,
      'pt-8 md:pt-0': isOpen,
    }
  )
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node) &&
        closeBtnRef.current && !closeBtnRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <div className={headerClass}>
      <Link to="/" className="flex justify-center w-full">
        <Image src={logo} alt="Laurn" className="w-1/2 sm:w-1/4" />
      </Link>
      <div className="flex md:hidden justify-between w-full" ref={closeBtnRef}>
        {
          isOpen ? (
            <Icon name="close" onClick={() => setIsOpen((o) => !o)} />
          ) : (
            <Icon name="menu" onClick={() => setIsOpen((o) => !o)} />
          )
        }
        <a href="https://www.instagram.com/lauren_decoration/" onClick={() => logEvent(analytics, "click_instagram")}>
          <Icon name={<Instagram className="h-6 w-6 fill-dark hover:fill-primary" />} />
        </a>
      </div>
      <ul className={menuClass} ref={menuRef}>
        <li className="text-base hover:text-primary">
          <Link to="/">Accueil</Link>
        </li>
        <li className="text-base hover:text-primary">
          <Link to="/about">À propos</Link>
        </li>
        <li className="text-base hover:text-primary">
          <Link to="/realisations">Réalisations</Link>
        </li>
        <li className="text-base hover:text-primary">
          <Link to="/prestations">Prestations</Link>
        </li>
        <li className="text-base hover:text-primary">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="hidden md:block">
          <a href="https://www.instagram.com/lauren_decoration/" onClick={() => logEvent(analytics, "click_instagram")}>
            <Icon name={<Instagram className="h-6 w-6 fill-dark hover:fill-primary" />} />
          </a>
        </li>
      </ul>
    </div>
  );
}
