import { doc, updateDoc } from "firebase/firestore";
import { StorageReference, ref as storageRef, deleteObject } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { useUploadFile } from "react-firebase-hooks/storage";
import Button from "src/components/base/Buttons/Button";
import Icon from "src/components/base/Icons/Icon";
import FirestoreImage from "src/components/base/Images/FirestoreImage";
import Input from "src/components/base/Inputs/Input";
import Body from "src/components/layout/Body";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "src/firebase";
import H1 from "src/components/base/Titles/H1";
import Realisation from "src/components/elements/Realisation";

export default function AdminEditView() {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [value, loading, error] = useDocument(
        doc(db, "realisations/" + id)
    );
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [metaDescription, setMetaDescription] = useState("");
    const [uploadFile, uploading] = useUploadFile();
    const [uploadedFiles, setUploadedFiles] = useState<{ file: StorageReference, comment?: string, alt?: string }[]>([]);
    const [cover, setCover] = useState<string | null>(null);
    const [preview, setPreview] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && !error && value) {
            setTitle(value.data()?.title);
            setDescription(value.data()?.description);
            setMetaDescription(value.data()?.metaDescription);
            setCover(value.data()?.cover);
            setUploadedFiles(value.data()?.images
                .map((image: any) => ({ file: { fullPath: image.fullPath }, comment: image.comment, alt: image.alt })) || []
            );
        }
    }, [value, loading, error])


    const handleUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const event = e.target as HTMLInputElement;
        if (!event.files?.length) return;

        for (let i = 0; i < event.files.length; i++) {
            const uploadedFile = event.files.item(i);
            if (!uploadedFile) return;
            const fileRef = storageRef(storage, `realisations/${uploadedFile.name}`);
            uploadFile(fileRef, uploadedFile)
                .then((snapshot) => {
                    if (!snapshot) return;
                    setUploadedFiles((old) => [...old, { file: snapshot.ref, comment: "", alt: "" }])
                    if (!cover)
                        setCover(snapshot.ref.fullPath);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
    const handleAddCommentOnImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, file: StorageReference) => {
        const event = e.target as HTMLInputElement;
        setUploadedFiles(old => old.map(f => f.file.fullPath === file.fullPath ? { ...f, comment: event.value } : f));
    }

    const handleAddTagOnImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, file: StorageReference) => {
        const event = e.target as HTMLInputElement;
        setUploadedFiles(old => old.map(f => f.file.fullPath === file.fullPath ? { ...f, alt: event.value } : f));
    }

    const handleUpdatePost = () => {
        updateDoc(doc(db, "realisations/" + id), {
            title,
            description,
            metaDescription,
            cover: cover,
            images: uploadedFiles.map((f) => ({
                fullPath: f.file.fullPath,
                comment: f.comment,
                alt: f.alt
            })),
            createdAt: new Date(),
        })
            .then(() => {
                setTitle("");
                setDescription("");
                setMetaDescription("");
                setUploadedFiles([]);
                setCover(null);
                navigate("/admin");
            });
    }
    const handleDelete = (file: StorageReference) => {
        deleteObject(storageRef(storage, file.fullPath))
            .then(() => {
                setUploadedFiles((old) => old.filter((f) => f.file.fullPath !== file.fullPath));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [preview])

    if (preview)
        return (
            <>
                <div className="flex w-full justify-end py-2">
                    <Button onClick={() => setPreview(false)} color="primary" size="large">
                        <Icon name="visibility_off" className="mr-4" />
                        Quitter la previsualisation
                    </Button>
                </div>
                <Realisation
                    title={title}
                    description={description}
                    images={uploadedFiles.map(f => ({ fullPath: f.file.fullPath, comment: f.comment, alt: f.alt }))}
                    cover={cover}
                    category="realisations"
                />
            </>
        )

    return (
        <Body size="full" color="primary">
            <H1 className="font-cherolina">Editer une réalisation</H1>
            <div className="space-y-4">
                <Input color="secondary" label="Titre" value={title} onChange={(e) => setTitle(e.target.value)} />
                <Input color="secondary" label="Description" value={description} onChange={(e) => setDescription(e.target.value)} type="textarea" />
                <Input color="secondary" label="Meta description" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} limit={158} />
                <Input color="secondary" label="Image" type="file" onChange={handleUpload} accept="image/*" multiple />
            </div>
            <div className="grid grid-cols-3 gap-4">
                {uploadedFiles.map(f => {
                    return (
                        <FirestoreImage
                            key={f.file.fullPath}
                            file={storageRef(storage, f.file.fullPath)}
                            selected={f.file.fullPath === cover}
                            setSelected={() => setCover(f.file.fullPath)}
                            alt={f.file.name}
                            color="secondary"
                            height={512}
                            width={512}
                        >
                            <Input color="secondary" placeholder="Description" type="textarea" value={f.comment} onChange={(e) => handleAddCommentOnImage(e, f.file)} />
                            <Input color="secondary" placeholder="Alt tag" value={f.alt} onChange={(e) => handleAddTagOnImage(e, f.file)} />
                            <Button color="secondary" onClick={() => handleDelete(f.file)} fullWidth disabled={loading} className="shrink-0">
                                Supprimer
                            </Button>
                        </FirestoreImage>
                    )
                })}
                {
                    uploading && (<div className="h-full w-full border border-secondary p-8">
                        <Icon name="autorenew" className="animate-spin text-secondary mr-4" />
                        Chargement...
                    </div>)
                }
            </div>
            <div className="self-end flex space-x-4">
                <Button color="secondary" size="large" plain onClick={() => setPreview(true)}>
                    <Icon name="visibility" className="mr-4" />
                    Previsualiser
                </Button>
                <Button onClick={handleUpdatePost} size="large" color="secondary">Mettre à jour</Button>
            </div>
        </Body>
    )
}