import Body from "src/components/layout/Body";
import { db, storage } from "src/firebase";
import { useCollection } from "react-firebase-hooks/firestore";
import { useUploadFile } from "react-firebase-hooks/storage";
import { addDoc, collection } from "firebase/firestore";
import { deleteObject, ref as storageRef, StorageReference } from "firebase/storage";
import { useEffect, useState } from "react";
import Input from "src/components/base/Inputs/Input";
import Button from "src/components/base/Buttons/Button";
import FirestoreImage from "src/components/base/Images/FirestoreImage";
import { useNavigate } from "react-router-dom";
import Icon from "src/components/base/Icons/Icon";
import H1 from "src/components/base/Titles/H1";
import Realisation from "src/components/elements/Realisation";
import H3 from "src/components/base/Titles/H3";

export default function AdminView() {
  const currentCollection = collection(db, "realisations");
  const [snapshot, loading, error] = useCollection(currentCollection, {});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [uploadFile, uploading] = useUploadFile();
  const [uploadedFiles, setUploadedFiles] = useState<{ file: StorageReference, comment?: string, alt?: string }[]>([]);
  const [cover, setCover] = useState<StorageReference | null>(null);
  const [preview, setPreview] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const event = e.target as HTMLInputElement;
    if (!event.files?.length) return;

    for (let i = 0; i < event.files.length; i++) {
      const uploadedFile = event.files.item(i);
      if (!uploadedFile) return;
      const fileRef = storageRef(storage, `realisations/${uploadedFile.name}`);
      uploadFile(fileRef, uploadedFile)
        .then((snapshot) => {
          if (!snapshot) return;
          setUploadedFiles((old) => [...old, { file: snapshot.ref, alt: "", comment: "" }])
          if (!cover)
            setCover(snapshot.ref);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const handleAddCommentOnImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, file: StorageReference) => {
    const event = e.target as HTMLInputElement;
    setUploadedFiles(old => old.map(f => f.file.fullPath === file.fullPath ? { ...f, comment: event.value } : f));
  }

  const handleAddTagOnImage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, file: StorageReference) => {
    const event = e.target as HTMLInputElement;
    setUploadedFiles(old => old.map(f => f.file.fullPath === file.fullPath ? { ...f, alt: event.value } : f));
  }

  const handleAddPost = () => {
    addDoc(currentCollection, {
      title,
      description,
      metaDescription,
      cover: cover?.fullPath,
      images: uploadedFiles.map((f) => ({
        fullPath: f.file.fullPath,
        comment: f.comment,
        alt: f.alt,
      })),
      createdAt: new Date(),
    })
      .then(() => {
        setTitle("");
        setDescription("");
        setUploadedFiles([]);
        setCover(null);
        navigate("/");
      });
  }

  const handleDelete = (file: StorageReference) => {
    deleteObject(file)
      .then(() => {
        setUploadedFiles((old) => old.filter((f) => f.file.fullPath !== file.fullPath));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [preview])

  if (preview)
    return (
      <>
        <div className="flex w-full justify-end py-2">
          <Button onClick={() => setPreview(false)} color="primary" size="large">
            <Icon name="visibility_off" className="mr-4" />
            Quitter la previsualisation
          </Button>
        </div>
        <Realisation
          title={title}
          description={description}
          images={uploadedFiles.map(f => ({ fullPath: f.file.fullPath, comment: f.comment, alt: f.alt }))}
          cover={cover?.fullPath}
          category="realisations"
        />
      </>
    )

  return (
    <div className="w-full">
      <Body size="full" color="primary">
        <H1 className="font-cherolina">Administration</H1>
        <H3>Editer une réalisation</H3>
        {(loading) && <p>Chargement des réalisations en ligne...</p>}
        {error && (<p>Error: {error.code}</p>)}
        <div className="grid grid-cols-4 gap-8">
          {snapshot &&
            snapshot.docs.map(doc => (
              <div className="flex flex-col space-y-4" key={doc.id}>
                <h2>{doc.data().title}</h2>
                <Button color="secondary" fullWidth to={`edit/${doc.id}`}>Editer</Button>
              </div>
            ))
          }
        </div>
      </Body>
      <Body size="full" color="light">
        <H3>Ajouter une réalisation</H3>
        <div>
          <Input label="Titre" value={title} onChange={(e) => setTitle(e.target.value)} />
          <Input label="Description" value={description} onChange={(e) => setDescription(e.target.value)} type="textarea" />
          <Input label="Meta description" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} limit={158} />
          <Input label="Image" type="file" onChange={handleUpload} accept="image/*" multiple />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {uploadedFiles.map(f => (
            <FirestoreImage
              key={f.file.fullPath}
              file={f.file}
              selected={f.file.fullPath === cover?.fullPath}
              setSelected={() => setCover(f.file)}
              alt={f.file.name}
              height={512}
              width={512}
            >
              <Input placeholder="Description" type="textarea" onChange={(e) => handleAddCommentOnImage(e, f.file)} value={f.comment} />
              <Input placeholder="Alt tag" onChange={(e) => handleAddTagOnImage(e, f.file)} value={f.alt} />
              <Button onClick={() => handleDelete(f.file)} fullWidth disabled={loading} className="shrink-0">
                Supprimer
              </Button>
            </FirestoreImage>
          ))}
          {
            uploading && (<div className="h-full w-full border border-primary p-8">
              <Icon name="autorenew" className="animate-spin text-primary mr-4" />
              Chargement...
            </div>)
          }
        </div>
        <div className="self-end flex space-x-4">
          <Button size="large" plain onClick={() => setPreview(true)}>
            <Icon name="visibility" className="mr-4" />
            Previsualiser
          </Button>
          <Button onClick={handleAddPost} size="large">Poster</Button>
        </div>
      </Body>
    </div>
  )
}