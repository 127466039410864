import { collection, limit, query, where } from "firebase/firestore";
import React from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Body from "src/components/layout/Body";
import { db, storage } from "src/firebase";
import { ref as storageRef } from "firebase/storage";
import Cover from "src/components/elements/Realisation/Cover";
import Link from "src/components/base/Links/Link";
import Button from "src/components/base/Buttons/Button";
import H3 from "src/components/base/Titles/H3";
import clsx from "clsx";

export default function RealisationSuggestions({ color, actualId }: RealisationSuggestionsProps) {
  const currentCollection = collection(db, "realisations");
  const [realisations] = useCollection(query(currentCollection, limit(3), where("title", "!=", actualId)));
  const titleClass = clsx(
    "p-4 text-dark text-xl m-16 break-normal w-2/3 text-center font-cherolina bg-secondary"
  )

  return (
    <Body color={color}>
      <H3 className="mb-4">Découvrez d'autres projets</H3>
      <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
        {
          realisations && realisations.docs.map((realisation) => (
            <div className="relative transition hover:opacity-75" key={realisation.id}>
              <Link to={`/realisation/${realisation.id}`}>
                <Cover
                  className="h-96 sm:h-128 md:h-144 lg:h-192 xl:h-96 2xl:h-128"
                  height={512}
                  width={512}
                  src={storageRef(storage, realisation.data().cover)}
                  category="Réalisation"
                  alt=""
                  background={color}
                  border={color === "light" ? "primary" : "secondary"}
                />
                <div className="absolute w-full bottom-0 flex justify-center">
                  <div className={titleClass}>
                    {realisation.data().title}
                  </div>
                </div>
              </Link>
            </div>
          ))
        }
      </div>
        <div className="col-start-2 min-w-fit w-full">
          <Button color={color === "light" ? "primary" : "secondary"} size="large" fullWidth to="/realisations">
            Découvrir toutes mes réalisations
          </Button>
        </div>

    </Body>
  )
}
