import React from "react";
import clsx from "clsx";
import H1 from "src/components/base/Titles/H1";
import H5 from "src/components/base/Titles/H5";

export default function TextImage({ children, image, title, subtitle, imagePosition = "left", button }: TextImageProps) {
  const divClass = clsx(
    [
      "flex flex-col",
      "md:space-x-16 xl:space-x-24",
      "space-y-8 md:space-y-0",
    ],
    {
      "md:flex-row-reverse md:space-x-reverse xl:space-x-reverse": imagePosition === "right",
      "md:flex-row": imagePosition === "left",
    }
  )

  return (
    <div className={divClass}>
      <div className="md:basis-1/2">
        {image}
      </div>
      <div className="flex flex-col justify-between pb-4 md:basis-1/2">
        <div className="mb-4">
          {subtitle && <H5 className="mb-8">{subtitle}</H5>}
          {title && <H1 className="mb-8 font-cherolina">{title}</H1>}
          {children}
        </div>
        {
          button
        }
      </div>
    </div>
  );
}