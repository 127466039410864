import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyA4AdtwrFqEAYvWykHCB7IqWQmr8CMfadM",
    authDomain: "laurn-decoration.firebaseapp.com",
    projectId: "laurn-decoration",
    storageBucket: "gs://laurn-decoration.appspot.com",
    messagingSenderId: "39185884197",
    appId: "1:39185884197:web:644df2048473c6ffda4bcc"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);