import React, { ReactElement } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import CookieBanner from '../elements/Cookies/CookieBanner';
import Footer from './Footer';
import Header from './Header';

export default function Layout(): ReactElement {
  return (
    <div className="flex flex-col items-center">
      <Header />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <CookieBanner/>
    </div>
  );
}
