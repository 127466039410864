import React from "react";
import Button from "src/components/base/Buttons/Button";
import Icon from "src/components/base/Icons/Icon";
import Image from "src/components/base/Images/Image";
import Input from "src/components/base/Inputs/Input";
import P from "src/components/base/Texts/P";
import Body from "src/components/layout/Body";
import { ReactComponent as Instagram } from "src/assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "src/assets/icons/facebook.svg";
import coverImage from "src/assets/img/page_contact.jpg";
import TextImage from "src/components/elements/Sections/TextImage";
import { addDoc, collection } from "firebase/firestore";
import { analytics, db } from "src/firebase";
import { logEvent } from "firebase/analytics";
import Error from "src/components/base/Errors/Error";

export default function ContactView() {
  const currentCollection = collection(db, "mail");
  const [firstname, setFirstname] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [when, setWhen] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [popup, setPopup] = React.useState<{
    show: boolean, message: string, type: "success" | "error"
  }>({ show: false, message: "", type: "error" });

  const handleSend = () => {
    if (!firstname || !name || !email || !message) {
      setPopup({ show: true, message: "Veuillez remplir tous les champs obligatoire (Nom, prénom, email et message)", type: "error" });
      return;
    }
    addDoc(currentCollection, {
      to: "contact@lauren-decoration.fr",
      message: {
        subject: "Demande de contact",
        html: `
          <h1>Demande de contact</h1>
          <p>Prénom: ${firstname}</p>
          <p>Nom: ${name}</p>
          <p>Email: ${email}</p>
          <p>Téléphone: ${phone}</p>
          <p>Quand: ${when}</p>
          <p>Message: ${message}</p>
        `
      }
    })
      .then(() => {
        setPopup({ show: true, message: "Votre message a bien été envoyé", type: "success" });
        logEvent(analytics, "send_mail");
        setFirstname("");
        setName("");
        setEmail("");
        setPhone("");
        setWhen("");
        setMessage("");
      })
      .catch(() => {
        setPopup({ show: true, message: "Une erreur est survenue, veuillez réessayer", type: "error" });
      });
  };

  return (
    <div>
      <Body color="primary" size="full">
        <TextImage
          title="Contactez-moi"
          image={
            <Image alt="Miroir chiné, banc et luminaire par LaureN décoration" src={coverImage} border="secondary" background="primary" />
          }
          button={
            <div className="grid grid-cols-1 md:grid-cols-2 gap-y-8">
              <a href="https://instagram.com/lauren_decoration" target="_blank" rel="noreferrer" className="hover:text-secondary text-base whitespace-nowrap" onClick={() => logEvent(analytics, "click_instagram")}>
                <Icon name={<Instagram className="fill-secondary w-6 h-6" />} color="secondary" className="mr-2" />
                lauren_decoration
              </a>
              <a href="mailto:laurendecoration@gmail.com" target="_blank" rel="noreferrer" className="hover:text-secondary text-base whitespace-nowrap" onClick={() => logEvent(analytics, "click_email")}>
                <Icon name="alternate_email" color="secondary" className="mr-2" />
                laurendecoration@gmail.com
              </a>
              <a href="https://www.facebook.com/LaureNdecoration/" target="_blank" rel="noreferrer" className="hover:text-secondary text-base whitespace-nowrap" onClick={() => logEvent(analytics, "click_facebook")}>
                <Icon name={<Facebook className="fill-secondary w-6 h-6" />} color="secondary" className="mr-2" />
                LaureN Décoration
              </a>
              <a href="tel:+33662378962" target="_blank" rel="noreferrer" className="hover:text-secondary text-base whitespace-nowrap" onClick={() => logEvent(analytics, "click_phone")}>
                <Icon name="phone" color="secondary" className="mr-2" />
                +33 6 62 37 89 62
              </a>
            </div>
          }
        >
          <P className="mb-4">
            Pour vos projets d'agencement d'espaces et de décoration d'intérieur, ou pour toute demande d'information, vous pouvez remplir le formulaire ci-dessous, ou me contacter par mail ou sur WhatsApp.
          </P>
          <P>
            Retrouvez-moi également sur les réseaux sociaux.
          </P>
        </TextImage>
      </Body>
      <Body color="light" size="full">
        {
          popup.show && (
            <Error
              type={popup.type}
              onClose={() => setPopup({ ...popup, show: false })}
              message={popup.message}
            />
          )
        }
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <Input required placeholder="Votre prénom" label="Prénom" onChange={(e) => setFirstname(e.target.value)} value={firstname} />
          <Input required placeholder="Votre nom" label="Nom" onChange={(e) => setName(e.target.value)} value={name} />
          <Input required placeholder="Votre email" type="email" label="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
          <Input placeholder="Votre téléphone" type="tel" label="Téléphone" onChange={(e) => setPhone(e.target.value)} value={phone} />
          <Input placeholder="Vos disponibilités" label="A quel moment souhaitez-vous être recontacté" onChange={(e) => setWhen(e.target.value)} value={when} />
          <Input required placeholder="Décrivez moi votre projet" label="Votre projet" type="textarea" className="sm:col-span-2" onChange={(e) => setMessage(e.target.value)} value={message} />
          <Button plain className="place-self-end sm:col-span-2 text-base" onClick={handleSend}>Valider</Button>
        </div>
      </Body>
      <Body color="primary" size="full">
        <blockquote>
          <svg aria-hidden="true" className="w-16 h-16 text-secondary" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="currentColor" />
          </svg>
          <p className="italic text-4xl mb-4 font-cherolina">
            Quand on ne peut pas changer le monde, il faut changer le décor.
          </p>
          <p>
            - Daniel Pennac
          </p>
        </blockquote>
      </Body>
    </div>
  )
}