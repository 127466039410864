import React from 'react'
import Button from 'src/components/base/Buttons/Button'
import Icon from 'src/components/base/Icons/Icon'
import Body from 'src/components/layout/Body'
import { ReactComponent as Instagram } from "src/assets/icons/instagram.svg";
import { ReactComponent as Facebook } from "src/assets/icons/facebook.svg";
import { logEvent } from 'firebase/analytics';
import { analytics } from 'src/firebase';
import clsx from 'clsx';

export default function ContactSection({ color = "light" }: ContactSectionProps) {
  const iconClass = clsx(
    "w-6 h-6",
    color === "light" ? "fill-primary" : "fill-secondary"
  )
  const linkClass = clsx(
    "sm:text-center text-base whitespace-nowrap",
    color === "light" ? "hover:text-primary" : "hover:text-secondary"
  )

  return (
    <Body color={color} size="full">
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 xl:gap-0 gap-8">
        <a href="https://instagram.com/lauren_decoration" target="_blank" rel="noreferrer" className={linkClass} onClick={() => logEvent(analytics, "click_instagram")}>
          <Icon name={<Instagram className={iconClass} />} color={color === "light" ? "primary" : "secondary"} className="mr-2" />
          lauren_decoration
        </a>
        <a href="https://www.facebook.com/LaureNdecoration/" target="_blank" rel="noreferrer" className={linkClass} onClick={() => logEvent(analytics, "click_facebook")}>
          <Icon name={<Facebook className={iconClass} />} color={color === "light" ? "primary" : "secondary"} className="mr-2" />
          LaureN Décoration
        </a>
        <a href="mailto:laurendecoration@gmail.com" target="_blank" rel="noreferrer" className={linkClass} onClick={() => logEvent(analytics, "click_email")}>
          <Icon name="alternate_email" color={color === "light" ? "primary" : "secondary"} className="mr-2" />
          laurendecoration@gmail.com
        </a>
        <a href="tel:+33662378962" target="_blank" rel="noreferrer" className={linkClass} onClick={() => logEvent(analytics, "click_phone")}>
          <Icon name="phone" color={color === "light" ? "primary" : "secondary"} className="mr-2" />
          +33 6 62 37 89 62
        </a>
      </div>
      <div className="min-w-fit w-full sm:w-1/3 self-center sm:pt-4">
        <Button to="/contact" color={color === "light" ? "primary" : "secondary"} size="large" fullWidth>
          Me contacter
        </Button>
      </div>
    </Body>
  )
}