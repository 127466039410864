import React, { ReactElement, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import routes from './router/routes';
import Middleware from './router/middlewares/Middleware';
import Layout from './components/layout/Layout';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function App(): ReactElement {
  const [route] = useState<AppRoute[]>(routes());
  const router = createBrowserRouter(
    [
      {
        path: '/',
        element: <Layout />,
        children: createRoutesFromElements(route.map((({
          path, loginRequired, logoutRequired, Element, pageTitle, pageDescription, pageCanonical, noIndex,
        }: AppRoute) => (
          <Route
            id={path}
            path={path}
            element={(
              <>
                <Helmet>
                  {pageTitle && <title>{pageTitle}</title>}
                  {pageDescription && <meta name="description" content={pageDescription} />}
                  {pageCanonical && <link rel="canonical" href={pageCanonical} />}
                  {noIndex && <meta name="robots" content="noindex" />}
                </Helmet>
                <Middleware loginRequired={loginRequired} logoutRequired={logoutRequired}>
                  <Element />
                </Middleware>
              </>
            )}
            key={path}
          />
        ))))
      }
    ]
  );

  return (
    <HelmetProvider>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </HelmetProvider>
  );
}
