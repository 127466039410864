import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import Button from '../components/base/Buttons/Button';
import H1 from '../components/base/Titles/H1';
import Input from '../components/base/Inputs/Input';
import Body from '../components/layout/Body';
import { auth } from 'src/firebase';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import Error from 'src/components/base/Errors/Error';

export default function LoginView(): ReactElement {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [
    signInWithEmailAndPassword,
    user,
    loading,
    error,
  ] = useSignInWithEmailAndPassword(auth);
  const navigate = useNavigate();

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    signInWithEmailAndPassword(identifier, password);
  };

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      navigate("/admin")
    };
  }, [user, loading, navigate]);

  return (
    <Body size="small">
      {
        error && <Error type="error" message={error.code} id={error.name} />
      }
      <H1 className="pb-12">Connexion</H1>
      <form onSubmit={handleLogin}>
        <Input
          label="Identifiant"
          placeholder="Identifiant"
          className="mb-4"
          value={identifier}
          autocomplete="username"
          onChange={(e) => setIdentifier(e.target.value)}
        />
        <Input
          label="Mot de passe"
          placeholder="Mot de passe"
          className="mb-8"
          type="password"
          value={password}
          autocomplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button fullWidth>Se connecter</Button>
      </form>
    </Body>
  );
}
