import React, { ReactElement } from 'react';
import clsx from 'clsx';

export default function Body({ children, className, size, color, direction = 'column', spacing = 8 }: BodyProps): ReactElement {
  const bodyClass = clsx(
    className,
    [
      'flex',
      'justify-center',
      'w-full',
      'content-center',
      'p-8 sm:p-16 md:p-32',
    ],
    {
      'max-w-md': size === 'small',
      'max-w-lg': size === 'medium',
      'max-w-3xl': size === 'large',
      'max-w-full': size === 'full',
      'bg-light text-dark': color === 'light',
      'bg-primary text-light': color === 'primary',
      'flex-row': direction === 'row',
      'flex-col': direction === 'column',
      'space-x-4': spacing === 4 && direction === 'row',
      'space-y-4': spacing === 4 && direction === 'column',
      'space-x-8': spacing === 8 && direction === 'row',
      'space-y-8': spacing === 8 && direction === 'column',
      'space-x-16': spacing === 16 && direction === 'row',
      'space-y-16': spacing === 16 && direction === 'column',
      'space-x-32': spacing === 32 && direction === 'row',
      'space-y-32': spacing === 32 && direction === 'column',
    },
  );

  return (
    <div className={bodyClass}>
      {children}
    </div>
  );
}
