import React, { ReactElement } from 'react';
import Body from '../../components/layout/Body';
import Button from '../../components/base/Buttons/Button';
import P from '../../components/base/Texts/P';
import H1 from '../../components/base/Titles/H1';
import Icon from '../../components/base/Icons/Icon';

export default function NotFoundView(): ReactElement {

  return (
    <Body size="medium">
      <H1 className="mb-16">404</H1>
      <P className="py-8">
        Not found
      </P>
      <Button to="/" fullWidth>
        <Icon name="home" className="mr-2" />
        Acceuil
      </Button>
    </Body>
  );
}
