import AboutView from 'src/views/AboutView';
import AdminEditView from 'src/views/AdminEditView';
import AdminView from 'src/views/AdminView';
import ContactView from 'src/views/ContactView';
import LoginView from 'src/views/LoginView';
import PrestationCoachingView from 'src/views/PrestationCoachingView';
import PrestationCompleteView from 'src/views/PrestationCompleteView';
import PrestationsView from 'src/views/PrestationsView';
import RealisationView from 'src/views/RealisationView';
import NotFoundView from 'src/views/utils/NotFoundView';
import IndexView from '../views/IndexView';
import RealisationsView from '../views/RealisationsView';

export default function getRoutes(): AppRoute[] {
  return [
    {
      path: '/',
      Element: IndexView,
      pageTitle: "LaureN Décoration | Agencement et décoration d'intérieur Aix-en-Provence",
      pageDescription: "Décoratrice d'intérieur et styliste déco en Provence, je suis à votre écoute pour vos projets d'agencement d'intérieur, d'aménagement et de décoration"
    },
    {
      path: '/login',
      Element: LoginView,
      loginRequired: false,
      noIndex: true,
    },
    {
      path: '/admin',
      Element: AdminView,
      loginRequired: true,
      pageTitle: "LaureN Décoration | Administration",
      noIndex: true,
    },
    {
      path: '/admin/edit/:id',
      Element: AdminEditView,
      loginRequired: true,
      pageTitle: "LaureN Décoration | Administration - Edition",
      noIndex: true,
    },
    {
      path: '/realisation/:id',
      Element: RealisationView,
    },
    {
      path: '/realisations',
      Element: RealisationsView,
      pageTitle: "LaureN Décoration | Réalisations d'aménagement et décoration d'intérieur Aix-en-Provence",
      pageDescription: "Découvrez les réalisations de LaureN Décoration, décoratrice d'intérieur sur le pays d'Aix et Marseille : agencement, aménagement et décoration d'intérieur"
    },
    {
      path: '/about',
      Element: AboutView,
      pageTitle: "Portrait de LaureN | Décoratrice d'intérieur Aix-en-Provence Marseille",
      pageDescription: "Décoratrice d'intérieur sur le pays d'Aix et Marseille, contactez-moi pour un lieu de vie beau, chaleureux et fonctionnel qui vous ressemble",
    },
    {
      path: '/contact',
      Element: ContactView,
      pageTitle: "Contacter la décoratrice LaureN Décoration Aix-en-Provence Marseille",
      pageDescription: "Contactez-moi pour vos projets d'agencement d'espace, d'aménagement et décoration d'intérieur sur Aix-en-Provence et alentours",
    },
    {
      path: '/prestations',
      Element: PrestationsView,
      pageTitle: "LaureN Décoration | Coaching déco ou mission d'agencement et décoration",
      pageDescription: "Les différentes formules et leur tarif pour vous accompagner dans votre projet d'aménagement et décoration d'intérieur sur Aix-en-Provence",
    },
    {
      path: '/prestations/coaching',
      Element: PrestationCoachingView,
      pageTitle: "LaureN Décoration | Visite-conseil en aménagement d'intérieur",
      pageDescription: "Description et tarifs des formules de visites-conseil pour vous aider dans votre projet d'aménagement et décoration d'intérieur sur le pays d'Aix Marseille",
    },
    {
      path: '/prestations/complete',
      Element: PrestationCompleteView,
      pageTitle: "LaureN Décoration | Home design - étude complète",
      pageDescription: "Description et tarifs d'une étude complète d'un projet d'agencement d'espace, d'aménagement et décoration d'intérieur sur Aix-en-Provence et alentours",
    },
    {
      path: '*',
      Element: NotFoundView,
      noIndex: true,
    }
  ];
}
