import React, { ReactElement } from 'react';
import clsx from 'clsx';
import placeholder from '../../../assets/img/placeholder.png';

export default function Image({
  className, border, background = 'none', alt, src, fit = 'cover',
}: ImageProps): ReactElement {
  const imageClass = clsx(
    className,
    [
      'w-full',
      'h-full',
      'pb-4'
    ],
    {
      'bg-primary': background === 'primary',
      'bg-secondary': background === 'secondary',
      'bg-light': background === 'light',
      'object-contain': fit === 'contain',
      'object-cover': fit === 'cover',
    },
  );
  const legendClass = clsx(
    [
      '-translate-x-1',
      'rotate-180',
      'pl-32 pr-2',
      'h-6',
      'font-light',
      'text-sm',
    ],
    {
      'text-secondary': background === 'primary',
      'bg-primary': background === 'primary',
      'text-dark': background === 'light',
      'bg-light': background === 'light',
      'bg-secondary': background === 'secondary',
    }
  );
  const borderClass = clsx(
    [
      'absolute',
      'top-2',
      'right-2',
      'h-full',
      'w-full',
      'rotate-180',
    ],
    {
      'border': !!border,
      'border-primary': border === 'primary',
      'border-secondary': border === 'secondary',
      'border-light': border === 'light',
    }
  );

  if (!border) {
    return (
      <img
        className={imageClass}
        src={src || placeholder}
        alt={alt}
      />
    );
  }

  return (
    <div className="relative">
      <img
        className={imageClass}
        src={src || placeholder}
        alt={alt}
      />
      <fieldset className={borderClass}>
        <legend className={legendClass}>
        </legend>
      </fieldset>
    </div>
  );
}
