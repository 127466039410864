import clsx from "clsx"
import { ref as storageRef } from "firebase/storage";
import Body from "src/components/layout/Body";
import { storage } from "src/firebase";
import Cover from "./Cover";

export default function RealisationImages({ images, color }: RealisationImagesProps) {
  const divClass = clsx(
    "grid gap-8",
    {
      "grid-cols-1": images.length === 1,
      "grid-cols-1 lg:grid-cols-2": images.length === 2 || images.length === 4,
      "grid-cols-1 xl:grid-cols-3 ": images.length === 3,
    }
  )
  const imageClass = clsx(
    "h-96 sm:h-128 md:h-144",
    {
      "lg:h-160 xl:h-192": images.length === 1,
      "lg:h-128 xl:h-144 2xl:h-192": images.length === 2 || images.length === 4,
      "lg:h-192 xl:h-96 2xl:h-128": images.length === 3,
    }
  )

  return (

    <Body size="full" color={color}>
      <div className={divClass}>
        {images.map((image, index) => (
          <Cover
            className={imageClass}
            key={index}
            src={storageRef(storage, image.fullPath)}
            alt={image.alt || ""}
            category=""
            border={color === "primary" ? "secondary" : "primary"}
            height={images.length === 1 ? 1024 : images.length === 3 ? 512 : 752}
            width={images.length === 1 ? 1024 : images.length === 3 ? 512 : 752}
          />
        ))}
      </div>
    </Body>
  )
}