import React from "react";
import { Helmet } from "react-helmet-async";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import Realisation from "src/components/elements/Realisation";
import { db } from "src/firebase";

export default function RealisationView() {
  const { id } = useParams<{ id: string }>();
  const [value, loading] = useDocument(
    doc(db, "realisations/" + id)
  );

  if (loading) return <div>Loading...</div>

  return (
    <>
    <Helmet>
      <title>
        LaureN Décoration | {value?.data()?.title} {value?.data()?.description?.split(" ").slice(0, 2).join(" ")}
      </title>
      <meta name="description" content={value?.data()?.metaDescription} />
    </Helmet>
      <Realisation
        title={value?.data()?.title}
        description={value?.data()?.description}
        cover={value?.data()?.cover}
        images={value?.data()?.images}
        category={value?.data()?.category}
      />
    </>
  )
}