
import React, { ReactElement } from "react";
import Button from "src/components/base/Buttons/Button";
import Image from "src/components/base/Images/Image";
import Cover from "src/components/elements/Realisation/Cover";
import Body from "src/components/layout/Body";
import P from "src/components/base/Texts/P";
import { useCollection } from "react-firebase-hooks/firestore";
import { collection, limitToLast, orderBy, query } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { db, storage } from "src/firebase";
import TextImage from "src/components/elements/Sections/TextImage";
import coverPrestation from "src/assets/img/acceuil_prestations.jpg";
import coverAbout from "src/assets/img/about.png"
import ContactSection from "src/components/elements/Sections/Contacts";
import Link from "src/components/base/Links/Link";

export default function IndexView(): ReactElement {
  const currentCollection = collection(db, "realisations");
  const [realisations, loading] = useCollection(query(currentCollection, orderBy("createdAt", "desc"), limitToLast(4)));

  return (
    <div>
      <Body size="full" color="primary" className="items-center">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 w-full">
          {
            realisations && realisations.docs.slice(1, 4).map((realisation) =>
              <div className="relative transition hover:opacity-75" key={realisation.id}>
                <Link to={`/realisation/${realisation.id}`} className="w-full">
                  <Cover
                    className="h-80 sm:h-48 md:h-64 lg:h-72 xl:h-96 2xl:h-128"
                    border="secondary"
                    background="primary"
                    src={storageRef(storage, realisation.data().cover)}
                    category="Réalisation"
                    alt={realisation.data().images.find((a: FirestoreImage) => a.fullPath === realisation.data().cover)?.alt}
                  />
                </Link>
              </div>
            )
          }
        </div>
        <div className="min-w-fit w-full sm:w-1/3">
          <Button color="secondary" size="large" fullWidth to="/realisations">
            Découvrir toutes mes réalisations
          </Button>
        </div>
      </Body>
      <Body size="full" color="light">
        <TextImage
          subtitle="Projet récent"
          title={realisations?.docs[0].data().title}
          button={
            <Button color="primary" size="large" fullWidth to={`/realisation/${realisations?.docs[0].id}`}>
              Consulter le projet
            </Button>
          }
          image={!loading &&
            <Cover
              src={storageRef(storage, realisations?.docs[0].data().cover)}
              category=""
              alt={realisations?.docs[0].data().images.find((a: FirestoreImage) => a.fullPath === realisations?.docs[0].data().cover)?.alt}
              background="light"
              border="primary"
              height={496}
              width={572}
            />
          }
        >
          <P className="whitespace-pre-wrap">
            {!loading ? realisations?.docs[0].data().description : "Chargement..."}
          </P>
        </TextImage>
      </Body>
      <Body size="full" color="primary">
        <TextImage
          image={<Image src={coverPrestation} alt="Coaching déco ou mission agencement et décoration par LaureN Décoration" border="secondary" background="primary" />}
          subtitle="Mes prestations"
          title="Visite-conseil ou Etude complète"
          imagePosition="right"
          button={
            <Button color="secondary" size="large" fullWidth to="/prestations">
              En savoir plus
            </Button>
          }
        >
          <P>
            Que vous ayez simplement besoin de conseils en aménagement et décoration d'intérieur pour vous lancer dans le réaménagement d'une pièce ou que vous cherchiez un professionnel de l'agencement et le design d'intérieurs pour repenser intégralement votre intérieur, je vous accompagne afin de créer ensemble un intérieur unique qui vous corresponde.
          </P>
        </TextImage>
      </Body>
      <Body size="full" color="light">
        <TextImage
          subtitle="Qui suis-je ?"
          title="Laure N - Design d'intérieurs"
          button={
            <Button color="primary" size="large" fullWidth to="/about">
              Faisons connaissance
            </Button>
          }
          image={<Image src={coverAbout} alt="Photo LaureN décoratrice d'intérieur, designer d'espaces Aix-en-Provence" border="primary" background="light" />}
        >
          <P className="mb-2">
            Décoratrice d'intérieur par passion, tout nouvel intérieur à réagencer, réaménager, meubler, décorer est un nouveau défi dans lequel j'ai à cœur de mettre toute mon énergie et mon enthousiasme pour créer un environnement à la fois fonctionnel et accueillant à votre image.
          </P>
          <P>
            Contactez-moi pour que nous rendions ensemble votre intérieur plus beau.
          </P>
        </TextImage>
      </Body>
      <ContactSection color="primary" />
    </div>
  );
}