import React from "react";
import Body from "src/components/layout/Body";
import TextImage from "../Sections/TextImage";
import Cover from "./Cover";
import { ref as storageRef } from "firebase/storage";
import { storage } from "src/firebase";
import P from "src/components/base/Texts/P";

export default function RealisationTextImages({ images, color }: RealisationImageTextProps) {
  return (
    <Body size="full" color={color}>
      {
        images.map((image, i) => (
          <TextImage
            key={i}
            image={
              <Cover
                src={storageRef(storage, image.fullPath)}
                alt={image.alt || ""}
                category=""
                className="h-96 sm:h-128 md:h-96 xl:h-160 2xl:h-192"
                border={color === "primary" ? "secondary" : "primary"}
                height={1024}
                width={1024}
              />
            }
            imagePosition={i % 2 === 0 ? "left" : "right"}
          >
            <P className="whitespace-pre-wrap">
              {image.comment}
            </P>
          </TextImage>
        ))
      }
    </Body>
  )
}