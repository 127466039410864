import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { builder } from '@invertase/image-processing-api';

export default function FirestoreImage({
  file,
  className,
  alt,
  selected = false,
  setSelected,
  children,
  color = "primary",
  width = 500,
  height = 500,
  lazy = true,
}: FirestoreImageProps) {
  const [optimizedUrl, setOptimizedUrl] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const divClassName = clsx(
    className,
    [
      'flex',
      'flex-col',
    ]
  )
  const imageClassName = clsx(
    [
      'overflow-hidden',
      'grow'
    ],
    {
      'border-4': selected,
      'border-primary': selected && color === 'primary',
      'border-secondary': selected && color === 'secondary',
      'cursor-pointer': setSelected,
    }
  )

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      setOptimizedUrl(`/img/?file=${file.fullPath}&width=${width}&height=${height}`);
    });
    resizeObserver.observe(imageRef.current as Element);
    return () => resizeObserver.disconnect();
  }, [file, width, height, imageRef]);

  return (
    <div className={divClassName}>
      <div className={imageClassName} onClick={() => setSelected && setSelected(!selected)}>
        <img src={optimizedUrl || ""} alt={alt} className="object-cover h-full w-full" loading={lazy ? "lazy": "eager"} height={imageRef.current?.height || height} width={imageRef.current?.width || width} ref={imageRef} />
      </div>
      {children}
    </div>
  );
}