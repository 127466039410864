import React from "react";
import FirestoreImage from "src/components/base/Images/FirestoreImage";

export default function RealisationHeader({ title, alt, src }: RealisationHeaderProps) {
  return (
    <div className="relative">
      <FirestoreImage className="h-96 sm:h-144 w-full object-cover" file={src} alt={alt} width={window.innerWidth} height={window.innerHeight / 2} lazy={false} />
      <div className="absolute w-full bottom-1/2 translate-y-1/2 flex justify-center">
        <div className="bg-secondary p-8 text-dark text-3xl break-normal w-4/5 md:w-1/2 text-center font-cherolina">
          {title}
        </div>
      </div>
    </div>
  )
}