import React, { ReactElement } from 'react';
import clsx from 'clsx';

export default function Input({
  className,
  disabled,
  label,
  border = true,
  onChange,
  placeholder,
  type = 'text',
  accept,
  value,
  checked,
  rows = 4,
  autocomplete = 'off',
  color = 'primary',
  inputRef,
  multiple = false,
  required = false,
  limit
}: InputProps): ReactElement {
  const componentClass = clsx(
    className,
    "text-base",
    {
      'text-primary': color === 'primary',
      'text-secondary': color === 'secondary',
      'text-dark': color === 'dark',
    },
  );
  const inputClass = clsx(
    [
      'w-full',
      'block',
      'placeholder:text-primary/50',
    ],
    {
      'border': border,
      'border-primary': color === 'primary' && border,
      'border-secondary': color === 'secondary' && border,
      'border-0': !border,
      'focus:ring-primary focus:border-primary': color === 'primary',
      'focus:ring-secondary focus:border-secondary': color === 'secondary',
      'focus:ring-dark focus:border-dark': color === 'dark',
      'p-2': border && type !== 'file',
      'mt-2': !!label,
      'accent-primary checked:bg-primary focus:bg-primary focus:checked:bg-primary hover:bg-primary hover:checked:bg-primary': color === 'primary' && type === 'checkbox',
      'accent-secondary checked:bg-secondary focus:bg-secondary focus:checked:bg-secondary hover:bg-secondary hover:checked:bg-secondary': color === 'secondary' && type === 'checkbox',
      'bg-transparent': type !== 'checkbox',
    },
    type === 'file' ? [
      color === "primary" && 'file:bg-primary',
      color === "secondary" && 'file:bg-secondary file:text-primary file:hover:bg-primary file:hover:text-secondary',
      'file:p-2',
      'file:mr-2',
      'file:cursor-pointer',
      'hover:file:bg-transparent',
      'hover:file:text-primary',
      'file:border-primary',
      'file:border-0',
      'file:border-r',
      'file:border-solid',
    ] : 'px-2',
  );

  return (
    <div className={componentClass}>
      <label hidden={!label} htmlFor={label}>{label}{required && "*"}</label>
      {
        type === 'textarea' ? (
          <textarea
            id={label}
            className={inputClass}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            rows={rows}
            maxLength={limit}
          />
        ) : (
          <input
            id={label}
            className={inputClass}
            type={type}
            accept={accept}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            disabled={disabled}
            autoComplete={autocomplete}
            ref={inputRef}
            multiple={type === 'file' && multiple}
            checked={checked}
            maxLength={limit}
          />
        )
      }
    </div>
  );
}
