import React from "react";
import { ref as storageRef } from "firebase/storage";
import P from "src/components/base/Texts/P";
import Body from "src/components/layout/Body";
import { storage } from "src/firebase";
import RealisationHeader from "./Header";
import RealisationImages from "./Images";
import RealisationTextImages from "./TextImages";
import ContactSection from "../Sections/Contacts";
import RealisationSuggestions from "./Suggestions";

function printPics(images: FirestoreImage[], id: string) {
  const components = [];
  let picNoComment = 0;
  let picComment = 0;
  let color = true;

  for (let i = 0; i < images.length; i++) {
    if (images[i].comment && (!images[i + 1]?.comment || !images[i + 1] || i >= picNoComment + 2)) {
      let imagesComment = images.slice(picComment, i + 1);
      picNoComment = i + 1;
      picComment = i;
      if (images[i + 1]?.comment)
        picComment = i + 1;
      components.push(
        <RealisationTextImages
          key={i}
          images={imagesComment}
          color={color ? "primary" : "light"}
        />
      );
      color = !color;
    }
    if (!images[i].comment && (images[i + 1]?.comment || !images[i + 1] || i >= picComment + 4)) {
      let imagesNoComment = images.slice(picNoComment, i + 1);
      picComment = i + 1;
      picNoComment = i;
      if (!images[i + 1]?.comment)
        picNoComment = i + 1;
      components.push(
        <RealisationImages
          key={i}
          color={color ? "primary" : "light"}
          images={imagesNoComment}
        />
      );
      color = !color;
    }
  }
  components.push(
    <RealisationSuggestions color={color ? "primary" : "light"} actualId={id} key="preview" />
  )

  if (components.length % 2 === 0) {
    components.push(
      <ContactSection color="primary" key="contact" />
    )
  }

  return (
    components
  )
}

export default function Realisation({
  cover,
  title,
  description,
  images,
}: RealisationProps) {
  const fileRef = storageRef(storage, cover);

  return (
    <div>
      <RealisationHeader src={fileRef} title={title} alt={images.find((a) => a.fullPath === cover)?.alt || description} />
      <Body size="full">
        <P className="whitespace-pre-wrap">{description}</P>
      </Body>
      {
        printPics(images.filter((image) => image.fullPath !== cover), title)
      }
    </div>
  )
}