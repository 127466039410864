import React from "react";
import Image from "src/components/base/Images/Image";
import TextImage from "src/components/elements/Sections/TextImage";
import Body from "src/components/layout/Body";
import coverImage from "src/assets/img/page_home_design.jpg";
import optionImage from "src/assets/img/page_home_design_2.jpg";
import tarifImage from "src/assets/img/page_home_design_3.jpg";
import P from "src/components/base/Texts/P";
import H1 from "src/components/base/Titles/H1";
import H3 from "src/components/base/Titles/H3";
import ContactSection from "src/components/elements/Sections/Contacts";
import Button from "src/components/base/Buttons/Button";

export default function PrestationCompleteView() {
  return (
    <div>
      <Body color="primary">
        <TextImage
          title="Home design"
          image={
            <Image
              alt="Réalisation d'une cheminée insert par LaureN décoration Aix-en-Provence"
              src={coverImage}
              border="secondary"
              background="primary"
              className="h-96 sm:h-128 md:h-96 xl:h-160 2xl:h-192"
            />
          }
          button={
            <div>
              <P className="mb-4">
                Découvrez également la visite conseil en aménagement et décoration d'intérieur.
              </P>
              <Button size="large" to="/prestations/coaching" color="secondary" fullWidth>
                Voir le détail du Coaching Déco
              </Button>
            </div>
          }
        >
          <P className="mb-2">
            Votre intérieur a besoin d'être repensé en termes de distribution des espaces, d'aménagement des pièces, de style de décoration...<br />
            La mission complète en agencement d'espaces, aménagement et décoration d'intérieur est faite pour vous. Ensemble, nous allons repenser, selon vos besoins et vos envies, l'organisation de votre espace intérieur, son aménagement et sa décoration, afin de faire de votre intérieur, un lieu agréable et fonctionnel qui vous ressemble.
          </P>
          <P className="mb-4">
            Que vous vouliez repenser une pièce ou une maison entière, contactez-moi pour m'exposer votre projet.
          </P>
        </TextImage>
      </Body>
      <Body color="light">
        <H1 className="font-cherolina">Contenu et déroulé</H1>
        <div className="grid grid-cols-1 2xl:grid-cols-3">
          <div className="mb-8 2xl:mb-0 2xl:mr-8">
            <H3 className="mb-4">Phase préliminaire</H3>
            <P className="mb-2">
              Prise de contact téléphonique pour déterminer la faisabilité de votre projet.
            </P>
            <P className="mb-2">
              Rendez-vous à domicile d'environ 1h pour comprendre vos besoins, vos envies, votre budget, définir le cahier des charges, relever quelques mesures.
            </P>
            <P>
              Remise du devis pour la mission de conseil en réaménagement d'espaces et décoration d'intérieur sous 1 semaine.
            </P>
          </div>
          <div className="border-y py-8 2xl:border-y-0 2xl:py-0 2xl:border-x 2xl:px-8 border-primary">
            <H3 className="mb-4">Phase d'avant-projet</H3>
            <P className="mb-2">
              Prise de mesures détaillées.
            </P>
            <P className="mb-2">
              Etude de conception, pouvant aller de 15j à 2 mois selon l'ampleur et la complexité du projet.
            </P>
            <P>
              Rendez-vous de présentation de l'avant-projet, à votre domicile, comprenant 2 ou 3 solutions :
            </P>
            <ul className="list-disc list-inside text-base">
              <li className="mb-2">
                Moodboard ou planches d'ambiance pour validation du style général du projet de décoration d'intérieur.
              </li>
              <li>
                Plan d'aménagements 2D et Vues 3D pour choix d'agencements et d'aménagements.
              </li>
            </ul>
          </div>
          <div className="mt-8 2xl:mt-0 2xl:ml-8">
            <H3 className="mb-4">Elaboration du projet final</H3>
            <P className="mb-2">
              Etude du projet selon les choix effectués à l'issue de l'avant-projet, de 15 jours à 2 mois selon l'ampleur et la complexité du projet.
            </P>
            <P className="mb-2">
              Présentation du projet final à votre domicile :
            </P>
            <ul className="list-disc list-inside text-base">
              <li className="mb-2">
                Plans 2D d'aménagement
              </li>
              <li className="mb-2">
                Vues 3D
              </li>
              <li className="mb-2">
                Planches matières, couleurs, matériaux
              </li>
              <li className="mb-2">
                Planches mobiliers, luminaires, objets déco
              </li>
              <li>
                Shopping-list
              </li>
            </ul>
          </div>
          <div className="2xl:col-start-2 mt-8 text-base">
            <Button to="/contact" color="primary" fullWidth>
              Prendre rendez-vous
            </Button>
          </div>
        </div>
      </Body>
      <Body color="primary">
        <TextImage
          title="En option"
          image={
            <Image
              alt="Réalisation d'une cheminée insert par LaureN décoration Aix-en-Provence"
              src={optionImage}
              border="secondary"
              background="primary"
              className="h-96 sm:h-128 md:h-96 xl:h-160 2xl:h-192"
            />
          }
          imagePosition="right"
        >
          <P className="mb-2">
            Sur demande et sur devis :
          </P>
          <ul className="list-disc list-inside text-base">
            <li className="mb-2">
              Présentation d'artisans pour la réalisation des travaux
            </li>
            <li className="mb-2">
              Prestation de conseils esthétiques et fonctionnels lors de la phase de travaux
            </li>
            <li className="mb-2">
              Accompagnement shopping déco
            </li>
            <li>
              Prise en charge des commandes de mobiliers, luminaires et objets de décoration
            </li>
          </ul>
        </TextImage>
      </Body>
      <Body color="light">
        <TextImage
          title="Tarifs"
          image={
            <Image
              alt="Réalisation d'une cheminée insert par LaureN décoration Aix-en-Provence"
              src={tarifImage}
              border="primary"
              background="light"
            />
          }
        >
          <P className="mb-2">
            Tarifs sur devis en fonction de la complexité de la mission
          </P>
          <ul className="list-disc list-inside text-base">
            <li className="mb-2">
              <span className="text-primary">35€/m²</span> pour une prestation standard d'aménagement et décoration d'intérieur
            </li>
            <li className="mb-2">
              <span className="text-primary">45€/m²</span> pour une prestation haut de gamme d'aménagement et décoration d'intérieur
            </li>
            <li className="mb-2">
              <span className="text-primary">55€/m²</span> pour une prestation standard d'agencement d'espaces (restructuration des volumes) et de décoration d'intérieur
            </li>
            <li>
              <span className="text-primary">65€/m²</span> pour une prestation complexe d'agencement d'espaces (restructuration des volumes) et de décoration d'intérieur
            </li>
          </ul>
        </TextImage>
      </Body>
      <ContactSection color="primary" />
    </div>
  );
} 