import React from "react";
import Button from "src/components/base/Buttons/Button";
import Image from "src/components/base/Images/Image";
import P from "src/components/base/Texts/P";
import TextImage from "src/components/elements/Sections/TextImage";
import coverImage from "src/assets/img/page_coaching_deco.jpg";
import Body from "src/components/layout/Body";
import H4 from "src/components/base/Titles/H4";
import ContactSection from "src/components/elements/Sections/Contacts";
import H1 from "src/components/base/Titles/H1";

export default function PrestationCoachingView() {
  return (
    <div>
      <Body color="primary">
        <TextImage
          title="Le coaching-déco"
          image={
            <Image
              alt="Choix luminaire coaching déco par LaureN décoration Aix-en-Provence"
              src={coverImage}
              border="secondary"
              background="primary"
            />
          }
          button={
            <div>
              <P className="mb-4">
                Découvrez également la mission complète en agencement d'espaces, amenagement et décoration d'intérieur.
              </P>
              <Button size="large" to="/prestations/complete" color="secondary" fullWidth>
                Voir le détail du Home Design
              </Button>
            </div>
          }
        >
          <P className="mb-2">
            Besoin d'aide pour réaménager votre espace intérieur, imaginer une nouvelle décoration qui vous corresponde ?
          </P>
          <P className="mb-2">
            Vous avez des idées, des envies, mais vous hésitez, vous doutez, vous aimeriez un avis professionnel, vous avez besoin d'un coup de pouce pour vous lancer dans le réaménagement de votre intérieur...
          </P>
          <P>
            Je vous propose une prestation de coaching en décoration d'intérieur évolutive qui s'adapte à vos besoins, vos envies et votre budget.
          </P>
        </TextImage>
      </Body>
      <Body color="light">
        <TextImage
          image={
            <div className="pb-4 h-full">
              <div className="flex items-center border border-primary p-8 mb-4 h-full">
                <div className="h-fit">
                  <H1 className="mb-4 font-cherolina">La visite-conseil à domicile</H1>
                  <ul className="list-disc list-inside mx-2 text-base">
                    <li className="mb-2">150€ pour une pièce simple</li>
                    <li className="mb-2">200€ pour une pièce complexe ou 2 pièces communicantes</li>
                    <li>Rendez-vous à domicile de 1h30 à 2h</li>
                  </ul>
                </div>
              </div>
            </div>
          }
          button={
            <Button size="large" to="/contact" color="primary" fullWidth>
              Prendre rendez-vous
            </Button>
          }
          imagePosition="right"
        >
          <H4 className="mb-4">
            Une formule allégée, des conseils oraux qui n'en sont pas moins précieux
          </H4>
          <P className="mb-2">
            Au cours d'un premier contact téléphonique, vous m'exposerez votre projet et nous déterminerons quelle prestation est faite pour vous.
          </P>
          <P className="mb-2">
            A l'issue de ce contact, je vous ferai parvenir un questionnaire simple et personnalisé afin de préparer au mieux notre rencontre.
          </P>
          <P>
            La visite-conseil aura lieu à votre domicile. J'y analyserai vos besoins, vos envies, vos goûts, les contraintes de votre intérieur, et vous donnerai mes conseils en terme d'aménagement de l'espace, de couleurs, de luminaires, mobilier... selon vos souhaits.
          </P>
        </TextImage>
      </Body>
      <Body color="primary">
        <TextImage
          image={
            <div className="pb-4 h-full">
              <div className="flex items-center border border-secondary p-8 mb-4 h-full">
                <div className="h-fit">
                  <H1 className="mb-4 font-cherolina">La visite-conseil avec compte-rendu détaillé</H1>
                  <ul className="list-disc list-inside mx-2 text-base">
                    <li className="mb-2">350€ pour une pièce simple</li>
                    <li className="mb-2">500€ pour une pièce complexe ou 2 pièces communicantes</li>
                    <li className="mb-2">Rendez-vous à domicile de 1h30 à 2h</li>
                    <li>Planches de synthèse du rendez-vous sous 1 semaine</li>
                  </ul>
                </div>
              </div>
            </div>
          }
          button={
            <Button size="large" to="/contact" color="secondary" fullWidth>
              Prendre rendez-vous
            </Button>
          }
        >
          <H4 className="mb-4">Tous mes conseils synthétisés dans un document pour vous permettre de les intégrer et de les partager plus facilement</H4>
          <P className="mb-2">
            Au cours d'un premier contact téléphonique, vous m'exposerez votre projet et nous déterminerons quelle prestation est faite pour vous.
          </P>
          <P className="mb-2">
            A l'issue de ce contact, je vous ferai parvenir un questionnaire simple et personnalisé afin de préparer au mieux notre rencontre.
          </P>
          <P className="mb-2">
            La visite-conseil aura lieu à votre domicile. J'y analyserai vos besoins, vos envies, vos goûts, les contraintes de votre intérieur, et vous donnerai mes conseils en terme d'aménagement de l'espace, de couleurs, de luminaires, mobilier... selon vos souhaits.
          </P>
          <P className="mb-2">
            A l'issue de ma visite à domicile vous recevrez un compte-rendu détaillé avec tous mes conseils pour l'aménagement de votre intérieur, présenté sous forme de planches de synthèse:
          </P>
          <ul className="list-disc list-inside mx-2 text-base">
            <li className="mb-2">Moodboard ou planche d'ambiance</li>
            <li className="mb-2">Conseils en terme d'aménagements</li>
            <li className="mb-2">Conseils couleurs et matériaux</li>
            <li>Exemples de modèles d'éléments de décoration</li>
          </ul>
        </TextImage>
      </Body>
      <Body color="light">
        <TextImage
          image={
            <div className="pb-4 h-full">
              <div className="flex items-center border border-primary p-8 mb-4 h-full">
                <div className="h-fit">
                  <H1 className="mb-4 font-cherolina">La visite-conseil complète</H1>
                  <ul className="list-disc list-inside mx-2 text-base">
                    <li className="mb-2">650€ pour une pièce simple</li>
                    <li className="mb-2">900€ pour une pièce complexe ou 2 pièces communicantes</li>
                    <li className="mb-2">Rendez-vous à domicile de 1h30 à 2h</li>
                    <li className="mb-2">Planches de synthèse du rendez-vous avec plans 2D et Vues 3D sous 2 semaines</li>
                    <li>Shopping-list en option</li>
                  </ul>
                </div>
              </div>
            </div>
          }
          button={
            <Button size="large" to="/contact" color="primary" fullWidth>
              Prendre rendez-vous
            </Button>
          }
          imagePosition="right"
        >
          <H4 className="mb-4">
            Un plan d'aménagement 2D et des vues 3D pour vous permettre de mieux vous projeter dans votre futur intérieur.
          </H4>
          <P className="mb-2">
            Au cours d'un premier contact téléphonique, vous m'exposerez votre projet et nous déterminerons quelle prestation est faite pour vous.
          </P>
          <P className="mb-2">
            A l'issue de ce contact, je vous ferai parvenir un questionnaire simple et personnalisé afin de préparer au mieux notre rencontre
          </P>
          <P className="mb-2">
            La visite-conseil aura lieu à votre domicile. J'y analyserai vos besoins, vos envies, vos goûts, les contraintes de votre intérieur, et vous donnerai mes conseils en terme d'aménagement de l'espace, de couleurs, de luminaires, de mobilier... selon vos souhaits.
          </P>
          <P className="mb-2">
            A l'issue de ma visite à domicile vous recevrez un compte-rendu détaillé avec tous mes conseils pour l'aménagement de votre intérieur, présenté sous forme de planches de synthèse, avec plans d'aménagement 2D et vues 3D pour vous permettre de mieux vous projeter dans votre futur intérieur:
          </P>
          <ul className="list-disc list-inside mx-2 mb-2 text-base">
            <li className="mb-2">Mood board ou planche d'ambiance</li>
            <li className="mb-2">Conseils en terme d'aménagements</li>
            <li className="mb-2">Conseils couleurs et matériaux</li>
            <li className="mb-2">Exemples de modèles d'éléments de décoration</li>
            <li className="mb-2">Plans d'aménagement 2D côtés</li>
            <li>Vues 3D</li>
          </ul>
          <P className="mb-2">
            En option, je peux vous fournir une shopping-list pour vous permettre d'effectuer vos achats en toute tranquilité,
          </P>
          <P>
            Egalement en option, des schémas côtés d'aménagements sur mesure
          </P>
        </TextImage>
      </Body>
      <ContactSection color="primary" />
    </div>
  );
}