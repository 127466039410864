import clsx from "clsx";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import Button from "src/components/base/Buttons/Button";
import P from "src/components/base/Texts/P";
import { setConsent } from "firebase/analytics"
import Input from "src/components/base/Inputs/Input";

function CookieDetails({ onClose }: { onClose: () => void }) {
    const [, setCookies] = useCookies(['cookie-consent'])
    const [gAnalytics, setGAnalytics] = useState(true);
    const [gAds, setGAds] = useState(true);

    const handleChangeConsent = (e: HTMLInputElement, cookie: string) => {
        let consent = e.checked ? "allow" : "deny";
        setConsent({ [cookie]: consent });
        setCookies("cookie-consent", true, { path: "/" })
    }

    return (
        <div className="fixed top-0 w-full h-screen bg-primary/50 flex items-center justify-center z-50">
            <div className="flex flex-col bg-light w-full md:w-2/3 lg:w-1/2 p-4 md:p-8">
                <P className="mb-2">
                    Les cookies sont des fichiers textes déposés sur votre ordinateur lors de la visite d'un site ou la consultation d'une publicité. Ils ont pour but de collecter des informations relatives à votre navigation et de vous adresser des services adaptés à vos besoins.
                </P>
                <P className="mb-2">
                    Les cookies utilisés sur ce site sont utilisés pour des besoins de statistiques et d'analyse de trafic. Ils nous permettent de connaître le nombre de visites, la page la plus consultée, etc.
                </P>
                <div className="flex flex-row justify-between items-center border-t border-primary py-4">
                    <P>
                        Cookie d'analyse de trafic : Google Analytics
                    </P>
                    <Input
                        type="checkbox"
                        checked={gAnalytics}
                        onChange={(e) => {
                            setGAnalytics(!gAnalytics);
                            handleChangeConsent(e.target as HTMLInputElement, "analytics_storage");
                        }}
                        className="border-secondary w-4"
                    />
                </div>
                <div className="flex flex-row justify-between items-center border-t border-primary py-4">
                    <P>
                        Cookie publicitaire : Google Adsense
                    </P>
                    <Input
                        type="checkbox"
                        checked={gAds}
                        onChange={(e) => {
                            setGAds(!gAds)
                            handleChangeConsent(e.target as HTMLInputElement, "ad_storage")
                        }}
                        className="border-secondary w-4"
                    />
                </div>
                <div className="flex flex-row justify-end items-center border-t border-primary py-4 space-x-2">
                    <Button
                        plain
                        onClick={() => {
                            setGAnalytics(true);
                            setGAds(true);
                            handleChangeConsent({ checked: true } as HTMLInputElement, "analytics_storage");
                            handleChangeConsent({ checked: true } as HTMLInputElement, "ad_storage");
                            onClose();
                        }}
                    >
                        Tout accepter
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setGAnalytics(false);
                            setGAds(false);
                            handleChangeConsent({ checked: false } as HTMLInputElement, "analytics_storage");
                            handleChangeConsent({ checked: false } as HTMLInputElement, "ad_storage");
                        }}
                    >
                        Tout refuser
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        Fermer
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default function CookieBanner() {
    const [cookies, setCookies] = useCookies(['cookie-consent'])
    const [showDetails, setShowDetails] = React.useState(false)
    const handleAccept = () => {
        setCookies("cookie-consent", true, { path: "/" })
        setConsent({
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
        })
    }
    const handleDecline = () => {
        setShowDetails(!showDetails)
    }

    const containerClass = clsx(
        [
            "fixed",
            "bottom-0",
            "left-0",
            "w-full",
            "bg-primary",
            "p-4",
            "border-t",
            "border-secondary",
            "flex",
            "flex-col md:flex-row",
            "justify-between",
            "items-center",
            "md:space-x-4",
            "space-y-4 md:space-y-0",
        ],
        {
            hidden: cookies["cookie-consent"] !== undefined
        }
    )

    return (
        <>
            {showDetails && <CookieDetails onClose={() => setShowDetails(false)} />}
            <div className={containerClass}>
                <P className="text-white">
                    Ce site utilise des cookies pour vous garantir la meilleure expérience. Vous pouvez les refuser si vous le souhaitez en cliquant sur le bouton "Details".
                </P>
                <div className="flex flex-row space-x-4">
                    <Button color="secondary" plain onClick={handleAccept}>
                        Accepter
                    </Button>
                    <Button color="secondary" onClick={handleDecline}>
                        Détails
                    </Button>
                </div>
            </div>
        </>
    )
}