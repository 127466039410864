import React from "react";
import Button from "src/components/base/Buttons/Button";
import Image from "src/components/base/Images/Image";
import P from "src/components/base/Texts/P";
import TextImage from "src/components/elements/Sections/TextImage";
import Body from "src/components/layout/Body";
import coverImage from "src/assets/img/page_presta_cover.jpg";
import coverCoaching from "src/assets/img/presta_coaching_deco.jpg";
import coverHomeDesign from "src/assets/img/presta_home_design.jpg";

export default function PrestationsView() {
  return (
    <div>
      <Body color="primary">
        <TextImage
          title="Se sentir chez soi comme nulle part ailleurs"
          image={
            <Image alt="Aménagement banquette DIY palette et coussins par LaureN décoration" src={coverImage} border="secondary" background="primary" />
          }
          button={
            <Button color="secondary" size="large" fullWidth to="/contact">
              Me contacter
            </Button>
          }
        >
          <P className="mb-2">
            Parce qu'un intérieur réussi est avant tout un lieu où l'on a envie de vivre, où l'on se sent bien,
          </P>
          <P className="mb-2">
            Parce que chacun est différent et que son lieu de vie se doit de l'être également, à son image,
          </P>
          <P>
            Je vous propose un accompagnement personnalisé pour créer ensemble l'intérieur qui vous corresponde.
          </P>
        </TextImage>
      </Body>
      <Body color="light">
        <TextImage
          title="Coaching déco - visite-conseil"
          image={
            <Image alt="Chambre avec sous-bassement vert conseils déco par LaureN Décoration" src={coverCoaching} border="primary" background="light" />
          }
          button={
            <Button color="primary" size="large" fullWidth to="/prestations/coaching">
              Voir la prestation en détail
            </Button>
          }
          imagePosition="right"
        >
          <P className="mb-2">
            Envie de réaménager votre espace intérieur, de changer la décoration intérieure de votre maison ou votre appartement ? Vous êtes en manque d'idées, vous avez des idées mais vous doutez, vous avez besoin de conseils, d'être guidés...
          </P>
          <P>
            La visite-conseil en aménagement d'intérieur et décoration ou coaching déco est faite pour vous.
          </P>
        </TextImage>
      </Body>
      <Body color="primary">
        <TextImage
          title="Home design - Etude complète "
          image={
            <Image alt="Création d'un espace bain-douche à l'italienne par LaureN Décoration" src={coverHomeDesign} border="secondary" background="primary" />
          }
          button={
            <Button color="secondary" size="large" fullWidth to="/prestations/complete">
              Voir la prestation en détail
            </Button>
          }
        >
          <P className="mb-2">
            Vous voulez tout changer dans votre intérieur qui ne correspond plus à vos goûts, ou à votre mode de vie, vous venez d'acquérir un nouveau bien et vous avez besoin de le remettre au goût du jour, ou simplement à votre goût, la distribution des espaces ne vous parait pas optimale... Vous ne savez pas comment vous y prendre, la tâche vous dépasse...
          </P>
          <P>
            Vous avez sûrement besoin d'une mission plus complète en architecture d'intérieur et décoration d'intérieur.
          </P>
        </TextImage>
      </Body>
    </div>
  )
}