import React from "react";
import Image from "src/components/base/Images/Image";
import P from "src/components/base/Texts/P";
import Body from "src/components/layout/Body";
import TextImage from "src/components/elements/Sections/TextImage";
import H4 from "src/components/base/Titles/H4";
import Button from "src/components/base/Buttons/Button";
import coverAbout from "src/assets/img/about.png"
import coverDeco from "src/assets/img/about_deco.jpg"
import ContactSection from "src/components/elements/Sections/Contacts";

export default function AboutView() {
  return (
    <div className="w-full">
      <Body color="primary" size="full" direction="row">
        <TextImage
          title="Quelques mots sur moi"
          imagePosition="left"
          image={
            <Image
              alt="Photo LaureN design d'intérieurs, décoration d'intérieur en Provence"
              src={coverAbout}
              border="secondary"
              background="primary"
            />
          }
          button={
            <Button size="large" to="/contact" color="secondary" fullWidth>
              Me contacter
            </Button>
          }
        >
          <P className="mb-4">
            Avant, je passais mes journées dans les budgets prévisionnels, les plans d'investissement, les bilans et comptes de résultat, je parlais actionnaires, rentabilité et plans de financement, mais je passais mon temps libre à peindre, feuilleter les magazines de déco, visiter des appartements que je rêvais de réaménager, rénover mes chez moi successifs...
          </P>
          <P>
            Jusqu'au jour où j'ai décidé de tout arrêter et de me former au design d'espaces et à la décoration d'intérieur afin de vivre de ma passion. Mais comme des années passées en gestion et finance d'entreprise laissent quelques traces, et même si ma nouvelle vie est indubitablement plus créative, je n'en oublie pas moins l'importance du respect des délais et des budgets.
          </P>
        </TextImage>
      </Body>
      <Body color="light" size="full" direction="row">
        <TextImage
          title="Mon idée de la décoration"
          imagePosition="right"
          image={
            <Image alt="Photo de nuanciers pour choix de couleurs en décoration d'intérieur" src={coverDeco} background="light" border="primary" />
          }
          button={
            <Button size="large" to="/realisations" color="primary" fullWidth>
              Découvrir mes réalisations
            </Button>
          }
        >
          <H4 className="mb-2">Du beau...</H4>
          <P className="mb-4">
            Amatrice d'art, j'aime la couleur, les jolies matières, les beaux motifs, l'harmonie. La recherche esthétique est à la base de tous mes projets créatifs. Mais comme tous les goûts et les couleurs sont dans la nature, j'ai à cœur que cette recherche soit la rencontre entre ma sensibilité et vos propres goûts.
          </P>
          <H4 className="mb-2">... et du fonctionnel,</H4>
          <P className="mb-4">
            J'aime les maisons, notamment celles qui ont une histoire, une âme, une présence. J'aime les défis imposés par les lieux. J'aime penser le réaménagement des pièces, et ne peux m'empêcher en entrant dans un lieu, d'imaginer son potentiel, de penser le réagencement des espaces, une nouvelle organisation des pièces. Un lieu de vie se doit d'être le plus fonctionnel possible, le mieux adapté au mode de vie de la famille qui l'habite, tout en conservant son caractère propre.
          </P>
          <H4 className="mb-2">Pour se sentir chez soi comme nulle part ailleurs</H4>
          <P>
            Car pour moi un intérieur réussi n'est pas seulement un lieu que l'on trouve beau sur le papier glacé des magazines de décoration, mais un lieu où l'on a envie de vivre, où l'on se sent serein, apaisé et à sa place.
          </P>
        </TextImage>
      </Body>
      <ContactSection color="primary" />
    </div>
  );
}