import React, { ReactElement } from 'react';
import Image from '../base/Images/Image';
import tmp1 from 'src/assets/img/logo/small_transparent.svg'
import P from '../base/Texts/P';
import Link from '../base/Links/Link';
import { useCollection } from 'react-firebase-hooks/firestore';
import { collection, limitToLast, orderBy, query } from 'firebase/firestore';
import { db } from 'src/firebase';

export default function Footer(): ReactElement {
  const currentCollection = collection(db, "realisations");
  const [realisations] = useCollection(query(currentCollection, orderBy("createdAt", "desc"), limitToLast(4)));

  return (
    <div className="p-8 sm:p-16 md:px-32 w-full">
      <div className="flex justify-between space-x-8 items-center py-8">
        <ul>
          <li>
            <Link className="no-underline" to="/">Accueil</Link>
          </li>
          <li>
            <Link className="no-underline" to="/about">À propos</Link>
          </li>
          <li>
            <Link className="no-underline" to="/prestations">Prestations</Link>
          </li>
          <li>
            <Link className="no-underline" to="/contact">Contact</Link>
          </li>
        </ul>
        <ul>
          <li>
            <Link className="no-underline" to="/realisations">Réalisations</Link>
          </li>
          <li>
            <Link className="no-underline" to="/prestations/coaching">Coaching Déco</Link>
          </li>
          <li>
            <Link className="no-underline" to="/prestations/complete">Home design</Link>
          </li>
        </ul>
        <ul>
          {
            realisations && realisations.docs.map((realisation) =>
              <li key={realisation.id}>
                <Link className="no-underline" to={`/realisation/${realisation.id}`}>{realisation.data().title}</Link>
              </li>
            )
          }
        </ul>
      </div>
      <div className="flex justify-between space-x-8 items-center">
        <Link to="/" className="w-full h-full">
          <Image src={tmp1} alt="LaureN" className="w-24 h-fit" fit="contain" />
        </Link>
        <P className="text-end text-xs md:text-sm">
          © 2022 LaureN Décoration | Site by
          <a href="https://romainneup.github.io" target="_blank" rel="noreferrer">Romain N.</a>
        </P>
      </div>
    </div>
  );
}
